<template>
  <span class="d-flex align-items-center">
    <span :class="status.icon" class="mr-2" /> {{ status.label }}
  </span>
</template>

<script>
import { STATUS, CHECKOUT_STATUS, RENT_STATUS } from '@/constants'

export default {
  name: 'Status',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    status() {
      switch (this.value) {
        case this.STATUS.ACTIVE.value:
          return {
            icon: 'fas fa-check text-success',
            label: 'Ativo'
          }
        case this.STATUS.INACTIVE.value:
          return {
            icon: 'fas fa-times text-danger',
            label: 'Inativo'
          }
        case this.STATUS.OPEN.value:
          return {
            icon: 'fas fa-check text-success',
            label: 'Aberto'
          }
        case this.STATUS.CLOSED.value:
          return {
            icon: 'fas fa-times text-danger',
            label: 'Fechado'
          }
        case this.STATUS.ENDED.value:
          return {
            icon: 'fas fa-check text-success',
            label: 'Finalizado'
          }
        case this.STATUS.CANCELLED.value:
          return {
            icon: 'fas fa-times text-danger',
            label: 'Cancelado'
          }
        case this.STATUS.PAUSED.value:
          return {
            icon: 'fas fa-pause-circle text-warning',
            label: 'Pausado'
          }
        case this.STATUS.RESCUED.value:
          return {
            icon: 'fas fa-address-card text-info',
            label: 'Resgatado'
          }
        default:
          return {}
      }
    }
  },
  created() {
    this.STATUS = {
      ...STATUS,
      ...RENT_STATUS,
      ...CHECKOUT_STATUS
    }
  }
}
</script>
